import Carrousel from "../../components/Carrousel"
import Welcome from "../../components/Welcome"
import WelcomeVoice from "../../components/WelcomeVoice"
import Up from "../../components/Up"
import '../../components/Up/up.scss'
import { advertising } from "../../datas/voices"
import { documentary } from "../../datas/voices"
import { film_animation } from "../../datas/voices"
import "./home.scss"
import LanguageContext from '../../context/index'
import { useContext, useState, useEffect, useRef } from 'react'
import VideoModal from "../../components/VideoModal"
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import TSParticles from '../../components/TSParticles'
import {RxDotFilled}  from 'react-icons/rx'
import Reveal from "../../components/Reveal"
import SEO from '../../components/SEO'

export default function Home(){
    const { lang } = useContext(LanguageContext)
    const [videoPlayer, setVideoPlayer] = useState(false)
    const parallaxRef = useRef()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [screenHeight, setScreenHeight] = useState(window.innerHeight)
    const myRef = useRef();
    const [ads, setAds] = useState(advertising)
    const [docs, setDocs] = useState(documentary)
    const [movie, setMovies] = useState(film_animation)

    useEffect(()=> {
        const adsGetStore = JSON.parse(localStorage.getItem('ads'))
        adsGetStore ? (setAds(adsGetStore)) : (localStorage.setItem('ads', JSON.stringify(advertising)))

        const docsGetStore = JSON.parse(localStorage.getItem('docs'))
        docsGetStore ? (setDocs(docsGetStore)) : (localStorage.setItem('docs', JSON.stringify(documentary)))
    
        const filmsGetStore = JSON.parse(localStorage.getItem('films'))
        filmsGetStore ? (setMovies(filmsGetStore)) : (localStorage.setItem('films', JSON.stringify(film_animation)))
    
    }, [])

    useEffect(() => {
        const scrollDown = screenHeight / 2

        let timer;
        function timeout(){
            (timer = setTimeout(() => {
                screenWidth > '821'?
                    parallaxRef.current.scrollTo(0.8)
                :
                    window.scrollTo({top: scrollDown, behavior: 'smooth'})
            }, 5500))

            return ()=>clearTimeout(timer)
        }
        
        return videoPlayer ? undefined : screenWidth > '420' ? timeout() : undefined;
        
    }, [screenHeight, screenWidth, videoPlayer]);

    function windowResize(){
        setScreenWidth(window.innerWidth)
        setScreenHeight(window.innerHeight)
    }

    window.addEventListener('resize', windowResize)
    window.addEventListener('orientationchange', windowResize)

    function toggleVideo(){
        if(videoPlayer === true) {
            setVideoPlayer(false)
        } else {
            setVideoPlayer(true)
        }
    }    

    return (
        screenWidth > '821'?
            <div className='home' style={{ height: '100vh' }}>
            <SEO
                title={lang === 'fr' ? 'Voix off Nathan Willcocks' : 'Voice over Nathan Willcocks'}
                description= {lang === 'fr' ? 'Présentation de Voix off réalisées par Nathan Willcocks' : 'Presentation of voice over from Nathan Willcocks'}
                name='Nathan Willcocks'
                type='article'
            />
            {videoPlayer ?
                <VideoModal
                    closeModal={toggleVideo}
                />
            :
            <>
                <TSParticles/>
                <Parallax pages={3} ref={parallaxRef}>
                    <ParallaxLayer className="parallax_background_1" offset={1} speed={1} />
                    <ParallaxLayer className="parallax_background_1" offset={2.5} speed={1} />
                    <ParallaxLayer
                        offset={0}
                        speed={0.1}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row'
                        }}
                    >
                    <>
                        <div className="welcome_box">
                            <Welcome  
                                welcomeName={<h1>Nathan Willcocks</h1>}
                            />
                            <WelcomeVoice
                                welcomeVoice={lang === 'fr' ? <h2>Voix off</h2> : <h2>Voice over</h2>}
                            />
                        </div>
                        <div className="icon_music_box">
                            <div className="icon_music_box_0"></div>
                            <div className="icon_music_box_1"></div>
                            <div className="icon_music_box_2"></div>
                            <div className="icon_music_box_3"></div>
                            <div className="icon_music_box_4"></div>
                            <div className="icon_music_box_5"></div>
                            <div className="icon_music_box_6"></div>
                            <div className="icon_music_box_7"></div>
                        </div>
                    </>
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={0.8}
                        speed={0.1}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Carrousel className="carrousel_1"
                            title={lang === 'fr' ? <h3>Publicités</h3> : <h3>Advertising</h3>}
                            pictures={ads}
                            toggleVideoPlayer={toggleVideo}
                            row= 'row'
                        />
                        <Reveal
                            num={1}
                        />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={1.4}
                        speed={0.2}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Carrousel className="carrousel_2"
                            title={lang === 'fr' ? <h3>Documentaires</h3> : <h3>Documentaries</h3>}
                            pictures={docs}
                            toggleVideoPlayer={toggleVideo}
                            row= 'row'
                        />
                        <Reveal
                            num={2}
                        />
                    </ParallaxLayer>
                    <ParallaxLayer
                        offset={2}
                        speed={0}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Carrousel className="carrousel_3"
                            title={lang === 'fr' ? <h3>Films <RxDotFilled/> Animations</h3> : <h3>Movies <RxDotFilled/> Animation</h3>}
                            pictures={movie}
                            toggleVideoPlayer={toggleVideo}
                            row= 'row'
                        /> 
                        <Reveal
                            num={3}
                        />
                        <Up
                            goUp={() => parallaxRef.current.scrollTo(0)}
                        />
                    </ParallaxLayer>
                </Parallax>
            </>
            }
            </div>
        :
        <div>
        {videoPlayer ?
            <div className='home' style={{ height: '100vh' }}>
            <SEO
                title={lang === 'fr' ? 'Voix off Nathan Willcocks' : 'Voice over Nathan Willcocks'}
                description= {lang === 'fr' ? 'Présentation de Voix off réalisées par Nathan Willcocks' : 'Presentation of voice over from Nathan Willcocks'}
                name='Nathan Willcocks'
                type='article'
            />
                <VideoModal
                    closeModal={toggleVideo}
                />
            </div>
            :
            <div className='home' style={{ height: '100vh' }}>
            <SEO
                title={lang === 'fr' ? 'Voix off Nathan Willcocks' : 'Voice over Nathan Willcocks'}
                description= {lang === 'fr' ? 'Présentation de Voix off réalisées par Nathan Willcocks' : 'Presentation of voice over from Nathan Willcocks'}
                name='Nathan Willcocks'
                type='article'
            />
                <TSParticles/>
                <div className="home_box">
                    <div className="welcome_box" ref={myRef}>
                        <Welcome  
                            welcomeName={<h1>Nathan Willcocks</h1>}
                        />
                        <WelcomeVoice
                            welcomeVoice={lang === 'fr' ? <h2>Voix off</h2> : <h2>Voice over</h2>}
                        />
                        <div className="icon_music_box">
                            <div className="icon_music_box_0"></div>
                            <div className="icon_music_box_1"></div>
                            <div className="icon_music_box_2"></div>
                            <div className="icon_music_box_3"></div>
                            <div className="icon_music_box_4"></div>
                            <div className="icon_music_box_5"></div>
                            <div className="icon_music_box_6"></div>
                            <div className="icon_music_box_7"></div>
                        </div>
                    </div>
                    <div className="carrousel_reveal_box">
                        <Carrousel className="carrousel_1"
                            title={lang === 'fr' ? <h3>Publicités</h3> : <h3>Advertising</h3>}
                            pictures={advertising}
                            toggleVideoPlayer={toggleVideo}
                            row='column'
                        />
                        {screenWidth > '420' &&
                            <Reveal
                                num={1}
                            />
                        }
                    </div>
                    <div className="carrousel_reveal_box">
                        <Carrousel className="carrousel_2"
                            title={lang === 'fr' ? <h3>Documentaires</h3> : <h3>Documentaries</h3>}
                            pictures={documentary}
                            toggleVideoPlayer={toggleVideo}
                            row='column'
                        />
                        {screenWidth > '420' &&
                            <Reveal
                                num={2}
                            />
                        }
                    </div>
                    <div className="carrousel_reveal_box">
                        <Carrousel className="carrousel_3"
                            title={lang === 'fr' ? <h3>Films <RxDotFilled/> Animations</h3> : <h3>Movies <RxDotFilled/> Animation</h3>}
                            pictures={film_animation}
                            toggleVideoPlayer={toggleVideo}
                            row='column'
                        /> 
                        {screenWidth > '420' &&
                            <Reveal
                                num={3}
                            />
                        }
                        <Up
                            goUp={() => (myRef.current.scrollIntoView(), myRef.current.focus())}
                        />
                    </div>
                </div>
            </div>
        }
        </div>
    )
}