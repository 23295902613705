import './langButton.scss';
import { useContext } from 'react'
import LanguageContext from '../../context/index.js'

export default function LangButton(){
    const { toggleLang, lang } = useContext(LanguageContext)

    const styles = {
        fontWeight: '900',
        borderBottom: 'solid 1px black'
    }

    return (
        <div className='switch_box'>
            <p style={lang === 'en' ? {...styles} : {fontWeight: '400'}}>EN</p>
            <label className="switch">
                <input type="checkbox" onClick={() => toggleLang()} aria-label={`change language to ${lang === 'en' ? 'french' : 'english'}`}/>
                <span className="slider round"></span>
            </label>
            <p style={lang === 'fr' ? {...styles} : {fontWeight: '400'}}>FR</p>
        </div>
    ) 
}