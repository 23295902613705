import AudioPlayer from '../../components/AudioPlayer'
import { advertising } from "../../datas/voices"
import { documentary } from "../../datas/voices"
import { film_animation } from "../../datas/voices"
import './download.scss'
import LanguageContext from '../../context/index'
import { useContext } from 'react'
import SEO from '../../components/SEO'

export default function Download(){
    const { lang } = useContext(LanguageContext)

    const audioTracks = [...advertising.filter(ad => ad.tags === 'audio'), ...documentary.filter(doc => doc.tags=== 'audio'), ...film_animation.filter(film => film.tags=== 'audio')]

    const sortedaudioTracks = audioTracks.sort((a,b) => a.place-b.place)

    return (
        <div className='audio_box'>
            <SEO
                title={lang === 'fr' ? 'Audios Nathan Willcocks' : 'Audios Nathan Willcocks'}
                description= {lang === 'fr' ? "Télécharger des extraits audios de Nathan Willcocks" : 'Download a panel of voices from Nathan Willcocks'}
                name='Nathan Willcocks'
                type='article'
            />
            {sortedaudioTracks.map(audio => 
                <AudioPlayer
                    key={audio.id}
                    track={audio}
                />
            )}
        </div>
    )
}