import './App.scss';
import {HashRouter as Router, Routes, Route} from 'react-router-dom'
import Home from "../src/pages/Home/index"
import About from './pages/About';
import Header from './components/Header';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import Error from './pages/Error';
import Download from './pages/Download';

function App() {

  return (
    <div className="App">
      <Router>
        <Header/>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/About' element={<About/>}></Route>
          <Route path='/Contact' element={<Contact/>}></Route>
          <Route path='/Download' element={<Download/>}></Route>
          <Route path='/*' element={<Error/>}></Route>
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
