import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from './context/contextProvider';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { HelmetProvider } from 'react-helmet-async';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <ThemeProvider>
//       <Provider store={store}>
//         <App />
//       </Provider>
//     </ThemeProvider>
//   </React.StrictMode>
// );

const rootElement = document.getElementById("root");

const APP = (<React.StrictMode>
                <ThemeProvider>
                  <Provider store={store}>
                    <HelmetProvider>
                      <App />
                    </HelmetProvider>
                  </Provider>
                </ThemeProvider>
              </React.StrictMode>)

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(document.getElementById("root"), APP);
  
} else {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(APP);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
