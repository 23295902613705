import { useState } from 'react';
import LanguageContext from './index'

export const ThemeProvider = ({children}) => {
    const [lang, setLang] = useState('en')
    const toggleLang = () => {
        setLang(lang === 'fr' ? 'en' : 'fr')
    }

    return (
        <LanguageContext.Provider value={{ lang, toggleLang }}>
            { children }
        </LanguageContext.Provider>
    )
}