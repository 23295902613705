import Vogue from "../assets/medias/advertising/Covers/VogueCover.webp"
import VogueThumb from "../assets/medias/advertising/Covers/VogueThumb.png"
import Joseph from "../assets/medias/advertising/Covers/JosephCover.webp"
import JosephThumb from "../assets/medias/advertising/Covers/JosephThumb.png"
import Hennessy from "../assets/medias/advertising/Covers/HennessyCover.webp"
import HennessyThumb from "../assets/medias/advertising/Covers/HennessyThumb.webp"
import Volvic from "../assets/medias/advertising/Covers/VolvicCover.webp"
import VolvicThumb from "../assets/medias/advertising/Covers/VolvicThumb.webp"
import HAV from "../assets/medias/advertising/Covers/HAVCover.webp"
import ATR from "../assets/medias/advertising/Covers/ATRCover.webp"
import AXA from "../assets/medias/advertising/Covers/AXACover.webp"
import AXAThumb from "../assets/medias/advertising/Covers/AXA.webp"
import Ponant from "../assets/medias/advertising/Covers/PonantCover.webp"
import OTT from "../assets/medias/advertising/Covers/OTTCover.webp"
import Carolina from "../assets/medias/advertising/Covers/CarolinaCover.webp"
import Djezzy from "../assets/medias/advertising/Covers/DjezzyCover.webp"
import Hublot from "../assets/medias/advertising/Covers/HublotCover.webp"
import HublotThumb from "../assets/medias/advertising/Covers/HublotThumb.png"

import Sanctuary from "../assets/medias/documentary/Covers/Sanctuary.webp"
import Impunity from "../assets/medias/documentary/Covers/Impunity_cover.webp"
import ImpunityThumb from "../assets/medias/documentary/Covers/Impunity.webp"
import Paris from "../assets/medias/documentary/Covers/Paris.webp"
import FirstMan from "../assets/medias/documentary/Covers/First_Man_cover.webp"
import FirstManThumb from "../assets/medias/documentary/Covers/First_Man.webp"
import Slavery from "../assets/medias/documentary/Covers/SlaveryCover.webp"
import SlaveryThumb from "../assets/medias/documentary/Covers/Slavery.webp"
// import Wild from "../assets/medias/documentary/Covers/WildFrance.webp" 
import MigratoryBirds from "../assets/medias/documentary/Covers/Migratory_cover.webp"
import MigratoryBirdsThumb from "../assets/medias/documentary/Covers/Migratory.webp"
import WildII from "../assets/medias/documentary/Covers/WildFranceII_cover.webp"

import Mans from "../assets/medias/film_animation/Covers/lemans_cover.webp"
import MansThumb from "../assets/medias/film_animation/Covers/lemans.webp"
import Calls from "../assets/medias/film_animation/Covers/CallsCover.webp"
import CallsThumb from "../assets/medias/film_animation/Covers/calls.webp"
import HorseHead from "../assets/medias/film_animation/Covers/horsehead_cover.webp"
import HorseHeadThumb from "../assets/medias/film_animation/Covers/horsehead.jpg"
import Bear from "../assets/medias/film_animation/Covers/Bear_cover.webp"
import BearThumb from "../assets/medias/film_animation/Covers/Bear.webp"
import Floaters from "../assets/medias/film_animation/Covers/Floaters_cover.webp"
import FloatersThumb from "../assets/medias/film_animation/Covers/Floaters.jpeg"
// import Avant from "../assets/medias/film_animation/Covers/AvantDeNeRienDevenir.webp"
import Mycroft from "../assets/medias/film_animation/Covers/Mycroft.webp"

import VogueAudio from "../assets/medias/audios/Vogue.mp3"
import JosephAudio from "../assets/medias/audios/Joseph.mp3"
import CarolinaAudio from "../assets/medias/audios/Carolina.mp3"
import OTTAudio from "../assets/medias/audios/OTT.mp3"
import PonantEnAudio from "../assets/medias/audios/PonantEn.mp3"
import HennessyAudio from "../assets/medias/audios/Hennessy.mp3"
import HublotAudio from "../assets/medias/audios/Hublot.mp3"
import VolvicAudio from "../assets/medias/audios/Volvic.mp3"

export const advertising = [
        {
            "id":2201,
            "media":"https://vimeo.com/241210149",
            "cover":Vogue,
            "thumb":VogueThumb,
            "title":"Vogue",
            "infos":{
                "director": "",
                "producer": "",
                "other": ""
            },
            "lang":"english",
            "audioSrc":VogueAudio,
            "tags":"audio",
            "place":5
        },
        {
            "id":2202,
            "media":"https://vimeo.com/415168145",
            "cover":Joseph,
            "thumb":JosephThumb,
            "title":"Ministère de l'écologie",
            "infos":{
                "director": "",
                "producer": "",
                "other": ""
            },
            "lang":"français",
            "audioSrc":JosephAudio,
            "tags":"audio",
            "place":6
        },
        {
            "id":2203,
            "media":"https://vimeo.com/524410801",
            "cover":Hennessy,
            "thumb":HennessyThumb,
            "title":"Richard Hennessy",
            "infos":{
                "director": "",
                "producer": "",
                "other": ""
            },
            "lang":"english",
            "audioSrc":HennessyAudio,
            "tags":"audio",
            "place":7
        },
        {
            "id":2204,
            "media":"https://vimeo.com/124599757",
            "cover":Volvic,
            "thumb":VolvicThumb,
            "title":"Volvic",
            "infos":{
                "director": "",
                "producer": "",
                "other": ""
            },
            "lang":"français",
            "audioSrc":VolvicAudio,
            "tags":"audio",
            "place":8
        },
        {
            "id":2205,
            "media":"https://vimeo.com/422383963",
            "cover":HAV,
            "thumb":HAV,
            "title":"HAV",
            "infos":{
                "director": "",
                "producer": "",
                "other": ""
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":10
        },
        {
            "id":2206,
            "media":"https://vimeo.com/134138918",
            "cover":ATR,
            "thumb":ATR,
            "title":"ATR",
            "infos":{
                "director": "",
                "producer": "",
                "other": ""
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":12
        },
        {
            "id":2207,
            "media":"https://vimeo.com/785675489",
            "cover":AXA,
            "thumb":AXAThumb,
            "title":"AXA",
            "infos":{
                "director": "",
                "producer": "",
                "other": ""
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":13
        },
        {
            "id":2208,
            "media":"https://vimeo.com/785674815",
            "cover":Carolina,
            "thumb":Carolina,
            "title":"Carolina Herrera",
            "infos":{
                "director": "",
                "producer": "",
                "other": ""
            },
            "lang":"english",
            "audioSrc":CarolinaAudio,
            "tags":"audio",
            "place":1
        },
        {
            "id":2209,
            "media":"https://vimeo.com/784999033",
            "cover":Ponant,
            "thumb":Ponant,
            "title":"Ponant",
            "infos":{
                "director": "",
                "producer": "",
                "other": ""
            },
            "lang":"english",
            "audioSrc":PonantEnAudio,
            "tags":"",
            "place":4
        },
        {
            "id":2210,
            "media":"https://vimeo.com/784999272",
            "cover":Ponant,
            "thumb":Ponant,
            "title":"Ponant",
            "infos":{
                "director": "",
                "producer": "",
                "other": ""
            },
            "lang":"français",
            "audioSrc":"",
            "tags":"",
            "place":3
        },
        {
            "id":2211,
            "media":"https://vimeo.com/784999664",
            "cover":OTT,
            "thumb":OTT,
            "title":"OTT",
            "infos":{
                "director": "",
                "producer": "",
                "other": ""
            },
            "lang":"english",
            "audioSrc":OTTAudio,
            "tags":"",
            "place":9
        },
        {
            "id":2212,
            "media":"https://vimeo.com/785673484",
            "cover":Djezzy,
            "thumb":Djezzy,
            "title":"Djezzy",
            "infos":{
                "director": "",
                "producer": "",
                "other": ""
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":11
        },
        {
            "id":2213,
            "media":"https://vimeo.com/788356784",
            "cover":Hublot,
            "thumb":HublotThumb,
            "title":"Hublot",
            "infos":{
                "director": "",
                "producer": "",
                "other": ""
            },
            "lang":"english",
            "audioSrc":HublotAudio,
            "tags":"audio",
            "place":2
        }
    ]
export const documentary = [
        {
            "id":2213,
            "media":"https://vimeo.com/785682764",
            "cover":Sanctuary,
            "thumb":Sanctuary,
            "title":"The Sanctuary",
            "infos":{
                "director": "Frédéric Fougea",
                "producer": "Production Boréales",
                "other": ""
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":1
        },
        {
            "id":2214,
            "media":"https://vimeo.com/49710971",
            "cover":Impunity,
            "thumb":ImpunityThumb,
            "title":"Impunity",
            "infos":{
                "director": "Juan José Lozano & Hollman Morris",
                "producer": "Production Intermezzo Films",
                "other": ""
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":2
        },
        {
            "id":2215,
            "media":"https://vimeo.com/785685692",
            "cover":MigratoryBirds,
            "thumb":MigratoryBirdsThumb,
            "title":"The Secret Route of Migratory Birds",
            "infos":{
                "director": "Franck Cuvelier",
                "producer": "Production Caroline Broussaud & Frédéric Fougea",
                "other": ""
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":3
        },
        {
            "id":2216,
            "media":"https://vimeo.com/219980424",
            "cover":Paris,
            "thumb":Paris,
            "title":"Paris A Wild Story",
            "infos":{
                "director": "Frédéric Fougea",
                "producer": "Co-production Boréales - Winds - Back Up Media - Terra Matter Factual Studios & Centre National du Cinéma & Mairie de Paris",
                "other": ""
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":4
        },
        // {
        //     "id":2217,
        //     "media":"",
        //     "cover":Wild,
        //     "title":"Wild France",
        //     "infos":{
        //         "director": "Frédéric Fougea",
        //         "producer": "Collaboration Jacques Malaterre Production Frédéric Fougea & Boréales",
        //         "other": ""
        //     },
        //     "lang":"english",
        //     "tags":"",
        //     "place":5
        // },
        {
            "id":2218,
            "media":"https://vimeo.com/118595266",
            "cover":WildII,
            "thumb":WildII,
            "title":"Wild France II",
            "infos":{
                "director": "Frédéric Fougea ",
                "producer": "Production Boréales",
                "other": ""
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":6
        },
        {
            "id":2219,
            "media":"https://vimeo.com/523808461",
            "cover":FirstMan,
            "thumb":FirstManThumb,
            "title":"First Man",
            "infos":{
                "director": "Frédéric Fougea",
                "producer": "Production Nilaya Productions & Boréales",
                "other": ""
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":8
        },
        {
            "id":2220,
            "media":"https://vimeo.com/785001133",
            "cover":Slavery,
            "thumb":SlaveryThumb,
            "title":"Slavery Routes",
            "infos":{
                "director": "Daniel Cattier & Juan Gélas",
                "producer": "Coproduction CPB Films (Cie des Phares & Balises), Arte France, Kwassa Films, LX Filmes Animations studios Autour de Minuit, Mikros Image.",
                "other": "Animation Olivier Patté"
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":7
        }
    ]
    export const film_animation = [
        {
            "id":2221,
            "media":"https://vimeo.com/785675264",
            "cover":Mans,
            "thumb":MansThumb,
            "title":"Le Mans 1955",
            "infos":{
                "director": "Quentin Bailleux",
                "producer": "Production Nicolas de Rosanbo & Carole Lambert",
                "other": ""
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":1
        },
        {
            "id":2222,
            "media":"https://vimeo.com/524191565",
            "cover":Mans,
            "thumb":MansThumb,
            "title":"Le Mans 1955",
            "infos":{
                "director": "Quentin Bailleux",
                "producer": "Production Nicolas de Rosanbo & Carole Lambert",
                "other": ""
            },
            "lang":"français",
            "audioSrc":"",
            "tags":"",
            "place":2
        },
        {
            "id":2223,
            "media":"https://vimeo.com/130420701",
            "cover":Bear,
            "thumb":BearThumb,
            "title":"The Bear",
            "infos":{
                "director": "Nathan Willcocks",
                "producer": "Production Nathan Willcocks",
                "other": ""
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":3
        },
        {
            "id":2224,
            "media":"https://vimeo.com/523220829",
            "cover":Calls,
            "thumb":CallsThumb,
            "title":"Calls",
            "infos":{
                "director": "Timothée Hochet",
                "producer": "Production Canal Plus",
                "other": ""
            },
            "lang":"français",
            "audioSrc":"",
            "tags":"",
            "place":4
        },
        {
            "id":2225,
            "media":"https://vimeo.com/785002823",
            "cover":Mycroft,
            "thumb":Mycroft,
            "title":"Mycroft",
            "infos":{
                "director": "Célestine Plays, Simon Gobbi, Tommy Toussaint, Soukaïna Saïle, Nelson Gabriel, Emeric Danchin & Camille Coroller",
                "producer": "Pôle IIID",
                "other": ""
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":5
        },
        {
            "id":2226,
            "media":"https://vimeo.com/785002925",
            "cover":HorseHead,
            "thumb":HorseHeadThumb,
            "title":"Horsehead",
            "infos":{
                "director": "Romain Basset",
                "producer": "Production HorseHead Pictures and Starfix Productions",
                "other": ""
            },
            "lang":"english",
            "audioSrc":"",
            "tags":"",
            "place":6
        },
        // {
        //     "id":2227,
        //     "media":"",
        //     "cover":Avant,
        //     "title":"Avant De Ne Rien Devenir",
        //     "infos":{
        //         "director": "Antoine Villiers",
        //         "producer": "",
        //         "other": ""
        //     },
        //     "lang":"français",
        //     "tags":"",
        //     "place":7
        // },
        {
            "id":2228,
            "media":"https://vimeo.com/785003641",
            "cover":Floaters,
            "thumb":FloatersThumb,
            "title":"Floaters",
            "infos":{
                "director": "Aurélie Garon",
                "producer": "Production Aurélie Garon Coproduction Le Fresnoy",
                "other": ""
            },
            "lang":"français",
            "audioSrc":"",
            "tags":"",
            "place":8
        }
    ]