import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'
import { selectVoices } from '../../redux/selector'
import './videoModal.scss'
import noVideo from '../../assets/medias/no-video.png'
import { useContext, useState } from 'react'
import LanguageContext from '../../context/index'
import PropTypes from 'prop-types'

export default function VideoModal({closeModal}){
    const { lang } = useContext(LanguageContext)
    const getMediaSelector = useSelector(selectVoices).voiceMedia.voiceMedia
    const getInfosSelectorThumb = useSelector(selectVoices).voiceThumb.voiceThumb
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    window.addEventListener('resize', ()=> {setScreenWidth(window.innerWidth)})

    return(
        <div className='videoWrap'>
            {(getMediaSelector === '')?
            <div className='noVideo_Box'>
                <img src={noVideo} alt='no video' className="noVideo"></img>
                {lang === 'fr' ?
                <p>Pas de vidéo disponible</p>
                :
                <p>No video available</p>
                }
            </div>
            :
            (screenWidth < '480') ?
                <div className='video_box'>
                    <ReactPlayer className="videoContainer" 
                        url={getMediaSelector}
                        width='100%'
                        height='100%'
                        muted={false} 
                        controls={true} 
                        light={getInfosSelectorThumb}
                        playsinline={false}
                        playing
                        pip
                        backgroundSize='contain'
                        backgroundRepeat='no-repeat'
                    
                    />
                </div>
            :
            <div className='video_box'>
                <ReactPlayer className="videoContainer" 
                    url={getMediaSelector}
                    width='100%'
                    height='100%'
                    muted={false} 
                    controls={true} 
                    light={true}
                    playsinline={false}
                    playing
                    pip
                />
            </div>
            }
            <div className='closeVideoPlayer' onClick={closeModal}>X</div>
        </div>
)
}

VideoModal.propTypes = {
    closeModal : PropTypes.func,
}