import './downloadButton.scss';
import { IoMdCloudDownload } from 'react-icons/io'
import { useContext } from 'react'
import LanguageContext from '../../context/index'
import PropTypes from 'prop-types'

export default function DownloadButton({trackSrc, trackTitle}){
    const { lang } = useContext(LanguageContext)

    return (
        <div className='download_box' aria-label='Download'>
            <p className='text'>{lang === 'fr' ? 'Télécharger' : 'Download'}</p>
            <a href={trackSrc} download={trackTitle} ><button type="button" className="download_button" aria-label='download the audio of the voice'><IoMdCloudDownload/></button></a>
        </div>
    )
}

DownloadButton.propTypes = {
    trackSrc : PropTypes.string,
    trackTitle : PropTypes.string,
}