// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import './header.scss';
import LangButton from "../../components/LangButton/index.js";
import { useContext, useEffect, useState } from 'react'
import LanguageContext from '../../context/index'

export default function Header(){
    const { lang } = useContext(LanguageContext)
    const [burger, setBurger] = useState(true)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [screenHeight, setScreenHeight] = useState(window.innerHeight)

    useEffect(()=> {
        setBurger(true)
    }, [])

    window.addEventListener('resize', ()=> {setScreenWidth(window.innerWidth); setScreenHeight(window.innerHeight)})

    return (
        <header className="headerContainer" >
            <LangButton/>
            {((screenWidth < '821') || (screenHeight < '555')) ? 
                burger ?
                <div className="burger_header">
                    <div onClick={()=> {burger ? setBurger(false) : setBurger(true)}} className='burger'>
                        <div className="burger_line"></div>
                        <div className="burger_line"></div>
                        <div className="burger_line"></div>
                    </div>
                </div>
                :
                <div className="headerWrap" style={{transition: 'border-radius 1s'}}>
                    <div onClick={()=> {burger ? setBurger(false) : setBurger(true)}} className='burger close'>
                        <div className="burger_line"></div>
                        <div className="burger_line"></div>
                        <div className="burger_line"></div>
                    </div>
                    {lang === 'fr' ?
                    <div className="header_link">
                        <Link to='/' className="headerHome" onClick={()=> setBurger(true)}>Accueil</Link>
                        <Link to='/About' className="headerHome" onClick={()=> setBurger(true)}>À Propos</Link>
                        <Link to='/Download' className="headerHome" onClick={()=> setBurger(true)}>Télécharger</Link>
                        <Link to='/Contact' className="headerHome" onClick={()=> setBurger(true)}>Contact</Link>
                    </div>
                    :
                    <div className="header_link">
                        <Link to='/' className="headerHome" onClick={()=> setBurger(true)}>Home</Link>
                        <Link to='/About' className="headerHome" onClick={()=> setBurger(true)}>About</Link>
                        <Link to='/Download' className="headerHome" onClick={()=> setBurger(true)}>Download</Link>
                        <Link to='/Contact' className="headerHome" onClick={()=> setBurger(true)}>Contact</Link>
                    </div>
                    }
                </div>
            :
                <div className="headerWrap">
                {lang === 'fr' ?
                <>
                    <Link to='/' className="headerHome">Accueil</Link>
                    <Link to='/About' className="headerHome">À Propos</Link>
                    <Link to='/Download' className="headerHome">Télécharger</Link>
                    <Link to='/Contact' className="headerHome">Contact</Link>
                </>
                :
                <>
                    <Link to='/' className="headerHome">Home</Link>
                    <Link to='/About' className="headerHome">About</Link>
                    <Link to='/Download' className="headerHome">Download</Link>
                    <Link to='/Contact' className="headerHome">Contact</Link>
                </>
                }
                </div>
            }
        </header>
    )
}