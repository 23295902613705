import './error.scss'
import { useContext } from 'react'
import LanguageContext from '../../context/index'

export default function Error(){
    const { lang } = useContext(LanguageContext)

    return (
        <div className='error_box'>
            <div className='error_wrap'>
                <div className='code_error'>404</div>
                {lang === 'fr' ? 
                    <div className='text_error'>
                    Erreur
                    <br/>La page que vous rechez n'existe pas.
                    <div><a href='/'>Retour à la page d'accueil</a></div>
                    </div>            
                    :
                    <div className='text_error'>
                    Error
                    <br/>The page you're looking for doesn't exist.
                    <div><a href='/'>Back to home page</a></div>
                    </div>
                }
            </div>
        </div>
    )
}