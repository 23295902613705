import React from 'react';
import './audioPlayer.scss'
import DownloadButton from '../DownloadButton'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer"
import { useEffect } from 'react'
import french from '../../assets/france.png'
import UK from '../../assets/united-kingdom.png'
import PropTypes from 'prop-types'

export default function AudioPlayer({track}) {
    const control = useAnimation()
    const [reference, inView] = useInView()

    useEffect(()=> {
        if(inView) {
            control.start('visible');
        }
    }, [control, inView])

    const boxVariant = {
        visible: { opacity: 1, rotateY:0, transition: { duration: 2}},
        hidden: { opacity: 0, rotateY:30 }
    }


	return (
		<motion.div className="audio-player"
            ref={reference}
            variants={boxVariant}
            initial='hidden'
            animate={control}
        >
            <img 
                className="voice_language"
                src={track.lang === 'français' ? french : UK}
                alt='voice language'
            />
			<div className="track-info">
                <img
                    className="artwork"
                    src={track.thumb}
                    alt={`track artwork for ${track.title}`}
                />
                <h4 className="title">{track.title}</h4>
                <div className='audio_controls'><audio src={track.audioSrc} controls controlsList="nodownload noplaybackrate"/>
                    <DownloadButton
                        trackSrc={track.audioSrc}
                        trackTitle={track.title}
                    />
                </div>
			</div>
		</motion.div>
	)
}

AudioPlayer.propTypes = {
    track : PropTypes.object,
}