import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import left from '../../assets/arrowLeft.svg'
import right from '../../assets/arrowRight.svg'
import "./carrousel.scss";
import { a, useSpring } from "react-spring";
import { useGesture } from '@use-gesture/react';
import { useDispatch } from 'react-redux';
import { watchVoiceMedia } from '../../redux/actions';
import french from '../../assets/france.png';
import UK from '../../assets/united-kingdom.png';
import play from '../../assets/play.png'
import CarrouselTitle from '../CarrouselTitle'

export default function Carrousel({pictures, title, toggleVideoPlayer, row}){
    const [style, setStyle] = useSpring(() => ({
        transform: "perspective(500px) rotateY(0deg)"
      }));
    const ref = useRef(null)
    const dispatch = useDispatch()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    const sortedPictures = pictures.sort((a,b) => a.place-b.place)

    const bind = useGesture({
        onScroll: (event) => {
            setStyle.start({
                transform: `perspective(500px) rotateY(${
                    event.scrolling ? clamp(event.delta[0]) : 0
                }deg)`
                });
        }
    });

    const clamp = (value, clampAt = 35) => {
        if (value > 0) {
          return value > clampAt ? clampAt : value;
        } else {
          return value < -clampAt ? -clampAt : value;
        }
    };

    const scroll = (scrollOffset) => {
        ref.current.scrollLeft +=scrollOffset
    }

    function selectVoiceMedia(media, infosDirector, infosProducer, infosOther, picTitle, picThumb){
        dispatch(watchVoiceMedia(media, infosDirector, infosProducer, infosOther, picTitle, picThumb))
    }

    window.addEventListener('resize', ()=> {setScreenWidth(window.innerWidth)})

    return (
        <>
        {(screenWidth > '480') ?
            <div className='carrousel_box' style={{ flexDirection: row }}>
                <CarrouselTitle 
                    title={title}
                />
                <div className="container" {...bind()} ref={ref}>
                <i className='carrouselArrowLeft' onClick={()=>scroll(-330)} ><img src={left} alt='left'/></i>
                    {sortedPictures.map(pic => (
                        <div className='card_box' key={`(${pic.id})`}>
                            <a.div className='card_lang'
                                style={{
                                    ...style,
                                }}
                                onClick={()=>{selectVoiceMedia(`${pic.media}`,`${pic.infos.director}`, `${pic.infos.producer}`, `${pic.infos.other}`, `${pic.title}`, `${pic.thumb}`); toggleVideoPlayer()}}
                            >
                                <img src={pic.lang === 'français' ? french : UK} alt='language'/>
                                <img src={play} className='play_logo' alt='player link'/>
                            </a.div>
                            <a.div
                                className="card"
                                alt={`${pic.title}` + ' video'}
                                style={{
                                    ...style,
                                    backgroundImage: `url(${pic.cover})`
                                }}
                                onClick={()=>{selectVoiceMedia(`${pic.media}`,`${pic.infos.director}`, `${pic.infos.producer}`, `${pic.infos.other}`, `${pic.title}`, `${pic.thumb}`); toggleVideoPlayer()}}
                            />
                        </div>
                    ))}
                <i className='carrouselArrowRight' onClick={()=>scroll(330)} ><img src={right} alt='right'/></i>
                </div>
            </div>
        :
            <div className='carrousel_box' style={{ flexDirection: row }}>
                <CarrouselTitle
                    title={title}
                />
                <div className="container" ref={ref} >
                <i className='carrouselArrowLeft' onClick={()=>scroll(-(330))}><img src={left} alt='left'/></i>
                    {sortedPictures.map(pic => (
                        <div className='card_box' key={`(${pic.id})`}>
                            <div className='card_lang' 
                                onClick={()=>{toggleVideoPlayer(); selectVoiceMedia(`${pic.media}`,`${pic.infos.director}`, `${pic.infos.producer}`, `${pic.infos.other}`, `${pic.title}`, `${pic.thumb}`)}}
                                style={{
                                    margin:'0',
                                }}
                            >
                                <img src={pic.lang === 'français' ? french : UK} alt='language'/>
                                <img src={play} className='play_logo' alt='player link'/>
                            </div>
                            <div
                                className="card"
                                alt={`${pic.title}` + ' video'}
                                style={{
                                    backgroundImage: `url(${pic.cover})`,
                                    margin:'0',
                                }}
                                onClick={()=>{toggleVideoPlayer(); selectVoiceMedia(`${pic.media}`,`${pic.infos.director}`, `${pic.infos.producer}`, `${pic.infos.other}`, `${pic.title}`, `${pic.thumb}`)}}
                            />
                        </div>
                    ))}
                    <i className='carrouselArrowRight' onClick={()=>scroll(330)}><img src={right} alt='right'/></i>
                </div>
            </div>
        }
        </>
    )
}

Carrousel.propTypes = {
    pictures : PropTypes.array.isRequired,
    title : PropTypes.object,
    toggleVideoPlayer : PropTypes.func,
    row : PropTypes.string,
}