import { configureStore } from "@reduxjs/toolkit";
import voiceReducer from "./reducer";

const reduxDevtools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

export const store = configureStore({
    reducer: {
        voiceMedia: voiceReducer,
        voiceInfosDirector: voiceReducer,
        voiceInfosProducer: voiceReducer,
        voiceInfosOther: voiceReducer,
        voiceInfosTitle: voiceReducer,
        voiceThumb: voiceReducer, 
    },
    reduxDevtools
});