import './welcomeVoice.scss';
import React, { useEffect, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer"
import PropTypes from 'prop-types'

export default function WelcomeVoice({ welcomeVoice }){ 
    const control = useAnimation()
    const [reference, inView] = useInView()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(()=> {
        if(inView) {
            control.start('visible');
        }
    }, [control, inView])
    
    const boxVariant = {
        visible: { opacity: 1, transition: { duration: 3, delay: 3} },
        hidden: { opacity: 0 }
    }

    function windowResize(){
        setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', windowResize)

    return (
        <motion.div className='welcomeVoice'
            ref={reference}
            variants={boxVariant}
            initial='hidden'
            animate={control}
            // num={num}
        >
            {welcomeVoice}
        </motion.div>
    )
}
WelcomeVoice.propTypes = {
    welcomeVoice : PropTypes.object,
}