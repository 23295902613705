import './welcome.scss';
import React, { useEffect, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer"
import PropTypes from 'prop-types'

export default function Welcome({ welcomeName }){ 
    const control = useAnimation()
    const [reference, inView] = useInView()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(()=> {
        if(inView) {
            control.start('visible');
        }
    }, [control, inView])
    
    const boxVariant = {
        visible: { opacity: 1, transition: { duration: 4, delay: 1} },
        hidden: { opacity: 0 }
    }

    function windowResize(){
        setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', windowResize)

    return (
        <motion.div className='welcome'
            ref={reference}
            variants={boxVariant}
            initial='hidden'
            animate={control}
        >
            {welcomeName}
        </motion.div>
    )
}

Welcome.propTypes = {
    welcomeName : PropTypes.object,
}