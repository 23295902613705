import { useContext, useEffect } from "react";
import LanguageContext from '../../context/index'
import "./about.scss"
import TSParticles from '../../components/TSParticles'
import BoxTextLeft from '../../components/BoxTextLeft'
import BoxTextRight from '../../components/BoxTextRight'
import '../../sass/_keyframes.scss';
import SEO from '../../components/SEO'
import {RxDotFilled}  from 'react-icons/rx'

export default function About(){
    const { lang } = useContext(LanguageContext)

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);


    return(
        <div className="about_wrap">
            <SEO
                title={lang === 'fr' ? 'À propos de Nathan Willcocks' : 'About Nathan Willcocks'}
                description= {lang === 'fr' ? "Présentation de l'acteur Nathan Willcocks" : 'Presentation of the actor Nathan Willcocks'}
                name='Nathan Willcocks'
                type='article'
            />
            <TSParticles/>
            {lang === 'fr' ?
                <div className="about_text_wrap">
                    <div className="about_text_wrap_left">
                        <BoxTextLeft
                        text= {<p>
                            En 2022, Nathan Willcocks fait partie du casting international en interprétant le <strong>Comte de Mercy</strong> pour la nouvelle création originale Canal+ <strong>MARIE-ANTOINETTE</strong>.
                            <br/>Actuellement diffusé sur Canal+, BBC2 & iPlayer et aux États-Unis sur PBS (printemps 2023). 
                            </p>}
                        />
                    </div>
                    <div className="about_text_wrap_right">
                        <BoxTextRight
                        text= {<p>
                            Depuis plus de 10 ans, en parallèle de sa carrière d'acteur, Nathan Willcocks prête sa voix à de nombreux documentaires, publicités et films d'animation primés dont Impunité, Wild France, Les Routes de l’Esclavage, The Sanctuary, Vogue, Le Mans 1955 pour n'en citer que quelques-uns.
                        </p>}
                        />
                    </div>
                    <div className="about_text_wrap_left">
                        <BoxTextLeft
                        text= {<div>
                            <p className="first_p">ACTUALITÉS CINÉMA & TV</p>
                            <p><span>Versailles</span> (Canal +) <RxDotFilled/> <span>Missions</span> (OCS) <RxDotFilled/> <span>Calls</span> (Canal +) <RxDotFilled/> <span>Compte tes Blessures</span> (Morgan Simon) <RxDotFilled/> <span>Lost in London</span> (Woody Harrelson) <RxDotFilled/> <span>Dernier Amour</span> (Benoît Jacquot) <RxDotFilled/> <span>L'Enfant Rêvé</span> (Raphaël Jacoulot) <RxDotFilled/> <span>Suzanna Andler</span> (Benoît Jacquot)</p>
                        </div>}
                        />
                    </div>
                </div>
                :
                <div className="about_text_wrap">
                    <div className="about_text_wrap_left">
                        <BoxTextLeft
                        text= {<p>
                            In 2022, Nathan Willcocks joined the main cast as <strong>Count Mercy</strong> in the BBC and Canal+ series <strong>MARIE-ANTOINETTE</strong>. 
                            <br/>Currently broadcast on Canal +, BBC2 & iPlayer and in the US on PBS (Spring 2023).
                        </p>}
                        />
                    </div>
                    <div className="about_text_wrap_right">
                        <BoxTextRight
                        text= {<p>
                            For more than 10 years, in parallel to his acting career Nathan Willcocks has lent his voice to numerous award-winning documentaries, commercials and animated films, such as Impunity, Wild France, Slavery Routes, The Sanctuary, Vogue, Le Mans 1955 to name a few.
                        </p>}
                        />
                    </div>
                    <div className="about_text_wrap_left">
                        <BoxTextLeft
                        text= {<div>
                            <p className="first_p">RECENT FILM & TV CREDITS</p>
                            <p><span>Versailles</span> (Canal +) <RxDotFilled/> <span>Missions</span> (OCS) <RxDotFilled/> <span>Calls</span> (Canal +) <RxDotFilled/> <span>A Taste of Ink</span> (Morgan Simon) <RxDotFilled/> <span>Lost in London</span> (Woody Harrelson) <RxDotFilled/> <span>Casanova Last Love</span> (Benoît Jacquot) <RxDotFilled/> <span>Dreamchild</span> (Raphaël Jacoulot) <RxDotFilled/> <span>Suzanna Andler</span> (Benoît Jacquot)</p>
                        </div>}
                    />
                    </div>
                </div>
            }
        </div>
    )
}