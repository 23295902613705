import { HiArrowNarrowUp } from "react-icons/hi";
import PropTypes from 'prop-types'

export default function Up({goUp}){

    return (
            <div className="upBox" onClick={goUp}>
                <HiArrowNarrowUp/>
            </div>
    )
}

Up.propTypes = {
    goUp : PropTypes.func.isRequired,
}