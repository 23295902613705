import './reveal.scss'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer"
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

export default function Reveal({num}){
    const control = useAnimation()
    const [reference, inView] = useInView() 
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(()=> {
        if(inView) {
            control.start('visible');
        }
    }, [control, inView])

    const translateVisible = screenWidth * 2
    
    const boxVariant = {
        visible: { transition: { duration: 3, delay: 0.5}, translateX: -translateVisible },
        hidden: { translateX: 0 }
    }

    function windowResize(){
        setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', windowResize)

    return (
        <motion.div className="carrousel_reveal" 
            ref={reference}
            variants={boxVariant}
            initial='hidden'
            animate={control}
            num={num}
        ></motion.div>
    )
}

Reveal.propTypes = {
    num : PropTypes.number,
}