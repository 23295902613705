import './footer.scss'
import { AiOutlineCopyrightCircle } from 'react-icons/ai';
import logo from '../../assets/logo.webp'

export default function Footer(){

    return(
        <div className='footer'>
            <div className='copy_box'><AiOutlineCopyrightCircle className='copyright'/><p>Copyright 2023 - Nathan Willcocks</p></div>
            <div className='createBy'>Créé par <img src={logo} alt='logo JH'/></div>
        </div>
    )
}