import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: null,
    voiceMedia: '',
    voiceInfosDirector: '',
    voiceInfosProducer: '',
    voiceInfosOther: '',
    voiceInfosTitle: '',
    voiceThumb: '',
}

const { actions, reducer } = createSlice({
    name: 'voicesMedia',
    initialState,
    reducers: {
        projectVoices: {
            prepare: (voice, voiceDirector, voiceProducer, voiceOther, voiceTitle, thumb) => ({ payload:{voice, voiceDirector, voiceProducer, voiceOther, voiceTitle, thumb}}),
            reducer: (draft, action) => {
                draft.voiceMedia = action.payload.voice
                draft.voiceInfosDirector = action.payload.voiceDirector
                draft.voiceInfosProducer = action.payload.voiceProducer
                draft.voiceInfosOther = action.payload.voiceOther
                draft.voiceInfosTitle = action.payload.voiceTitle
                draft.voiceThumb = action.payload.thumb
                return;
            }
        },
    }
})

export { actions }
export default reducer